<template>
  <div>
    <div style="background: whitesmoke; padding: 20px;">
      <a @click="$emit('goBack')">
        <i class="mdi mdi-chevron-left" /> Retour
      </a>
      <h2 class="title is-2" style="margin: 0;">
        Templates en ligne
      </h2>
    </div>
    <div style="display: grid; grid-template-columns: 300px calc(100% - 300px);">
      <div class="templates-menu" style="background: whitesmoke;">
        <b-menu>
          <b-menu-list label="General">
            <b-menu-item v-for="t in templates" :key="t._id" :icon="t.icon" :label="t.name" @click="selectedTemplate = t" />
          </b-menu-list>
        </b-menu>
      </div>
      <div style="padding: 20px;">
        <div v-if="selectedTemplate">
          <h2 class="title is-2">
            <i :class="`mdi mdi-${selectedTemplate.icon}`" />&nbsp;{{selectedTemplate.name}}
          </h2>
          {{selectedTemplate.description}}
          <h3 class="title is-3">
            Options
          </h3>
          <vue-form-generator
            ref="form"
            :schema="selectedTemplate.form"
            :model="selectedTemplate.defaultFormValue"
            class="form"
            tag="div"
          />
          <h3 class="title is-3">
            Fichiers à créer
          </h3>
          <div class="card" v-for="d in selectedTemplate.documents">
            <div class="card-content" style="display: flex">
              <span class="tag">
                <i class="mdi mdi-folder" />&nbsp;
                {{d.collection}}
              </span>
              <span style="flex-grow: 1">
                {{d.name}}
              </span>
              <span class="tag is-warning">
                Permissions: TODO
              </span>
              &nbsp;
              <b-dropdown v-model="d.skipFile" aria-role="list" append-to-body>
                <template #trigger>
                  <a v-if="d.skipFile" class="button is-small is-danger" style="width: 140px;">
                    <i class="mdi mdi-download-off" />&nbsp;
                    Ne pas installer
                    <i class="mdi mdi-chevron-down" />
                  </a>
                  <a v-else class="button is-small is-success" style="width: 140px;">
                    <i class="mdi mdi-download" />&nbsp;
                    Installer
                    <i class="mdi mdi-chevron-down" />
                  </a>
                </template>
                <b-dropdown-item :value="false" aria-role="listitem">
                  <i class="mdi mdi-download"/>
                  Installer
                </b-dropdown-item>
                <b-dropdown-item :value="true" aria-role="listitem">
                  <i class="mdi mdi-download-off"/>
                  Ne pas installer
                </b-dropdown-item>
              </b-dropdown>
              <portal to="templatePanelCreatorFooter">
                <p class="control" v-if="selectedTemplate">
                  <a class="button is-primary" @click="installTemplate">
                    <i class="mdi mdi-download" />&nbsp;
                    Installer
                  </a>
                </p>
              </portal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Handlebars from 'handlebars';

export default {
  data() {
    this.fetchRepositoryInfo();
    return {
      templates: [],
      formData: {},
      selectedTemplate: undefined,
    }
  },
  methods: {
    async fetchRepositoryInfo() {
      const res = await this.$axios.get(`https://dashy-referentiel.herokuapp.com/dashy-repository`, {
        headers: { authorization: `apiKey ${process.env.VUE_APP_REFERENTIEL_API}` },
      });
      this.templates = res.data.documents;
    },
    installTemplate() {
      console.log('install');
      for(const d of this.selectedTemplate.documents) {
        const template = Handlebars.compile(d.template);
        console.log(this.selectedTemplate.defaultFormValue);
        const templateParams = { ...this.selectedTemplate.defaultFormValue };
        const newDocument = template(templateParams);
        console.log(newDocument);
      }
    }
  }
};
</script>
<style scoped>
.templates-menu >>> ul {
  list-style: none !important;
  margin:  0;
}
</style>
